

/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i&display=swap');
@font-face {
    font-family: "OpenSans-Regular";
    src: url("./assets/fonts/OpenSans-Regular.eot");
    /* IE9 Compat Modes */
    src: url("./assets/fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/OpenSans-Regular.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/OpenSans-Regular.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/OpenSans-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/OpenSans-Regular.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/OpenSans-Regular.woff2") format("woff2");
    /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}


/* font converted using font-converter.net. thank you! */

@font-face {
    font-family: "OpenSans-Bold";
    src: url("./assets/fonts/OpenSans-Bold.eot");
    /* IE9 Compat Modes */
    src: url("./assets/fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/OpenSans-Bold.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/OpenSans-Bold.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/OpenSans-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/OpenSans-Bold.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/OpenSans-Bold.woff2") format("woff2");
    /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

html,
body {
    min-height: 100%;
    height: 100%;
}

body {
    background: #f5f5f5;
    font-family: 'Roboto', sans-serif;
}

.loadicon{
    position: absolute;
    background: rgba(255, 255, 255, 0.77);
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    bottom: 0;
}

/*payment gateway loading*/
.spinner {
    width: 200px;
    height: 100px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .spinner > div {
    background-color: #FFD000;
    height: 100%;
    width: 20px;
    display: inline-block;
    
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    margin-left: 2px;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
  


main{flex: 1 1 auto!important;}
:focus {
    outline: none;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
    border-bottom: 2px solid #28a3af;
}

.form-control {
    transition: 0.2s cubic-bezier(0.4, 0, 0.4, 1);
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
}

p {
    color: #1a1446;
    font-size: 14px;
}

.policy-deatil-card {
    background: #fff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 5px 0px #d9d9d9;
    margin: 30px auto;
    padding: 15px 30px;
    position: relative;
    min-height: 650px;
    padding-bottom: 55px;
}

.title h1 {
    font-size: 26px;
    color: #1a1446;
    line-height: 30px;
}

.title p {
    font-size: 14px;
    color: #808080;
}

.policy-deatil-card .form-group label {
    font-size: 14px;
    color: #343741;
}

.required-field {
    color: red
}

.alertBox {
    border-color: #dc3545;
}

.alertBox:focus {
    border-color: #dc3545;
}

.change-screen {
    transition: all linear 0.5s;
}

.next-btn.btn-primary[disabled] {
    background: #e9e9e9;
    border-color: #e9e9e9;
    color: rgba(26, 19, 70, 0.5);
    border-radius: 50px;
    padding: 9px 47px;
    font-size: 14px;
}

app-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    height: 100%;
}


/* loader css */

.loader-overlay {
    background: rgba(255, 255, 255, 0.21);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.spinner-border {
    width: 4rem;
    height: 4rem;
}

router-outlet~* {
    height: 100%;
    width: 100%;
}

.tooltip-icon {
    position: relative;
    display: inline-block;
}

.tooltip-icon .tooltiptext {
    font-size: 14px;
    visibility: hidden;
    background-color: #fff;
    color: #808080;
    text-align: left;
    border-radius: 6px;
    padding: 10px 10px;
    border: 1px solid #d7d7d7;
    box-shadow: 0 3px 3px #d7d7d7;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    margin-left: -21px;
    left: 0;
    margin-bottom: 14px;
}

.tooltip-icon .tooltiptext .tooltip-inner-text {
    max-width: 380px;
    white-space: nowrap;
}

.tooltiptext p {
    margin-bottom: 0px;
    color: #808080;
}

.tooltip-icon:hover .tooltiptext {
    visibility: visible;
}

.tooltip-icon .tooltiptext::after {
    content: " ";
    position: absolute;
    top: auto;
    left: 25px;
    margin-left: -5px;
    border: none;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    height: 20px;
    width: 20px;
    transform: rotate(-135deg);
    background: #fff;
    box-shadow: -3px -3px 2px 0px rgba(215, 215, 215, 0.77);
}

.tooltip-icon .tooltiptext.t-position::after {
    top: 85%;
}

ul.list-group-popup li strong {
    color: #000;
}


/* datepicker style */

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border: 1px solid #ccc;
    border-width: 1px;
}

.dt-btn button.btn-light {
    border-color: #ccc;
    background: #fff;
    border-radius: 50px;
    padding: 11px 27px;
    font-size: 14px;
}

.dt-btn button.btn-light:hover {
    background: #28a3af;
    color: #fff;
}

.e-input-group-icon.e-date-icon,
.e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: 26px;
    color: #fff;
    background: #28a3af;
    margin-bottom: 0px;
    padding: 5px;
}

.e-input-group,
.e-input-group.e-control-wrapper {
    border: 1px solid #ccc !important;
}

input#datepicker_input {
    padding: 5px 10px !important;
    font-family: "Roboto",sans-serif;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-datepicker .e-model-header {
    background-color: #28a3af;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day {
    border: 1px solid #28a3af;
    color: #28a3af;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day {
    background: #eee;
    border: 1px solid #28a3af;
    color: #28a3af;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
    background: #28a3af;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
    color: #fff;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input {
    padding-left: 15px;
}

input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) {
    border-width: 1px;
    border-color: #ccc;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
    color: #28a3af
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
    background-color: rgba(23, 162, 184, 0.18);
    color: #28a3af
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
    background-color: #28a3af;
    color: #fff;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
    border: 1px solid #28a3af;
    color: #28a3af;
}

.e-calendar .e-content td.e-today.e-selected span.e-day {
    background-color: #28a3af;
    border: 1px solid #28a3af;
}

.e-input-group .e-clear-icon,
.e-input-group.e-control-wrapper .e-clear-icon {
    padding-bottom: 4px;
}


/* scrollbar css */

.d-max-screen::-webkit-scrollbar {
    width: 7px;
}

.d-max-screen::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #efefef;
    border-radius: 10px;
}


/* Handle */

.d-max-screen::-webkit-scrollbar-thumb {
    background: #b9b7b7;
    border-radius: 10px;
}


/* Handle on hover */

.d-max-screen::-webkit-scrollbar-thumb:hover {
    background: #7b7a7a;
}


/* end of datepicker css */

@media only screen and (min-width: 768px) {
    /* .tooltip-icon .tooltiptext {
        width: 380px;
    } */
    .change-screen {
        height: 100%;
    }
}

@media(max-width: 991px) {
    .sm-center {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    /* [class*=' col-'], */
    .col-12 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .tooltip-icon .tooltiptext {
        left: 50%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
        margin-left: 0px;
        padding: 10px 5px;
    }
    .tooltip-icon .tooltiptext .tooltip-inner-text {
        width: 190px;
        text-align: center;
        white-space: normal;
        font-size: 13px;
    }
    .tooltiptext p {
        font-size: 13px;
    }
    .tooltip-icon .tooltiptext::after {
        left: 50%;
    }
    .tooltip-icon .tooltiptext.t-position::after {
        top: auto;
    }
    .policy-deatil-card{margin-top: 128px;}
    .e-datepick-mob-popup-wrap .e-datepicker.e-popup-wrapper.e-lib.e-popup.e-control.e-popup-open {
        transform: translate(-50%, -50%);
        left: 50% !important;
        top: 50% !important;
    }
}

@media only screen and (max-width: 576px) {
    .note-text p {
        font-size: 12px;
    }
    .support-link {
        line-height: 20px;
        margin-right: 10px;
    }
    .logo-text {
        flex-wrap: wrap;
    }
    .logo-text h3 {
        font-size: 20px !important;
    }
   
}


/* @media only screen and (max-width: 400px) {
    .tooltip-icon .tooltiptext .tooltip-inner-text {
        width: 150px;
    }
} */


/* common css for all claiming  components */

.radio-box {
    position: relative;
    margin-bottom: 15px;
}

.radio-box label {
    border: 1px solid #ccc;
    color: #06748c;
    background-color: #fff;
    padding: 14px 20px;
    font-size: 14px;
    width: 100%;
    text-align: center;
    height: 100%;
    margin: 0;
    font-family: "Roboto",sans-serif;font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio-box input[type=radio] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.radio-box input[type=radio]:checked+label {
    background-color: #28a3af;
    color: #fff;
}

.radio-box:hover label {
    background-color: #28a3af;
    color: #fff;
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
    background: #007bff;
}

@media only screen and (min-width: 577px) {
    .radio-box {
        flex-basis: 48.5%;
    }
}

@media only screen and (max-width: 576px) {
    .radio-box {
        flex-basis: 100%;
        margin-right: 0px;
    }
    .title h1 {
        font-size: 20px;
    }
}


/* edit button */

.edit-btn .btn {
    color: #fff!important;
    font-size: 14px;
    background: #006675;
    padding: 2px 14px;
    border-radius: 10px;
}


/* next button */

.next-btn.btn-primary {
    background-color: #99e5ea;
    border-color: #99e5ea;
    color: #000;
    border-radius: 50px;
    padding: 9px 47px;
    font-size: 14px;
    white-space: nowrap;
    font-family: "Roboto",sans-serif;font-weight: 700;
}
@media(min-width: 1200px){
    .my-qr-code img{width: 120px;}
}
@media(min-width: 992px) and (max-width: 1200px){
    .my-qr-code img{width: 120px;
   
    }
    .qr-mob img{
        margin-right: 14px;
    }
}



@media(min-width: 992px) {
    .next-btn.my-btn {
        margin-left: 175%;
        margin-top: 0px;
        position: relative;
        z-index: 1;
    }
    .mh-100 {height: 100%;}
    
}

.error_text {
    font-size: 14px;
    color: red;
}

/* .my-qr-code>img {
    width: 190px;
} */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=number] {
    -moz-appearance:textfield;
}
input[type=number]:focus {
    -moz-appearance: value;
}
select::-ms-expand {	display: none; }
select{
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
     -o-appearance: none;
    }


    /* warning message */
    .warning-message span {
        font-weight: 500;
    }